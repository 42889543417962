import { uniqueId } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { AssignedDate } from './AssignedDate';
import { CalendarEvent } from './CalendarEvent';
import { colors } from './colors';
import { EventColor } from './EventColor';
import { IAssignedDate } from './IAssignedDate';
import { IProgram } from './IProgram';
import { IProgramExercise } from './IProgramExercise';
import { ProgramExercise } from './ProgramExercise';

export class Program implements IProgram {
  getExercisesList(): any {
    return this.exercises.filter(
      (e) => !e.deleted && e.assigned_dates?.length > 0
    );
  }
  getExerciseById(id: string): ProgramExercise | undefined {
    return this.exercises.find(e => e.exercise_id == id)
  }
  deepClone(): Program {
    return new Program({
      _id: this.getProgramId(),
      id: this.getProgramId(),
      program_id: this.getProgramId(),
      exercises: this.exercises
        .filter((e) => !e.deleted && e.assigned_dates?.length > 0)
        .map((e) => {
          return new ProgramExercise({
            ...e,
            id: e.exercise_id,
            exercise_id: e.exercise_id,
            _id: e.exercise_id,
            deleted: false,
          });
        }),
      active: this.active,
      deleted: this.deleted,
    });
  }
  program_id: string;
  deleted?: boolean;
  active?: boolean;
  lang: string = 'en';
  _events!: CalendarEvent[];
  events$!: BehaviorSubject<CalendarEvent[]>;
  disabled?: boolean | undefined;
  exercises: IProgramExercise[];
  exercises$: BehaviorSubject<IProgramExercise[]> = new BehaviorSubject<
    IProgramExercise[]
  >([]);
  getDayEvents(date: Date): CalendarEvent[] {
    const events = this._events.filter(
      (e) => e.start.getTime() == date.getTime()
    );
    return events;
  }

  addExercise(exercise: IProgramExercise, otherProgramsEvents: CalendarEvent[]) {
    const currentTime = new Date();
    currentTime.setHours(0, 0, 0, 0);
    const futureDates: number[] = [];
    const futureEvents = this._events.filter((e) => {
      return e.start.getTime() >= currentTime.getTime();
    });
    for (const e of futureEvents) {
      const index = futureDates.indexOf(e.start.getTime());

      if (index < 0) {
        futureDates.push(e.start.getTime());
      }

    }

    // const pe = new ProgramExercise({
    //   ...exercise,
    //   program_id: this.program_id,
    // }); //{ ...exercise } as IProgramExercise;

    //needs review
    const programExercise = new ProgramExercise({
      ...exercise,
      exercise_id: exercise.exercise_id,
      period: exercise.period,
      deleted: false,
      program_id: this.getProgramId(),
      assigned_dates: futureDates.map((fd) => {
        return new AssignedDate({ date: new Date(fd), performed: false, period: exercise.period });
      }),
    });
    for (const date of futureDates) {
      const d = new Date(date)
      const exists = otherProgramsEvents.findIndex(e => e.start.getTime() == d.getTime() && e.meta?.exercise_id == programExercise.exercise_id && e.meta?.period == programExercise.period)
      if (exists == -1) {
        this.addEvent(programExercise, d);
      }
    }
    const ex_index = this.exercises.findIndex(e => e.exercise_id == exercise.exercise_id)
    if (ex_index > -1) {
      this.exercises[ex_index].deleted = false
      const dates = this.exercises[ex_index].assigned_dates
      dates.push.apply(dates, futureDates.map((fd) => {
        return new AssignedDate({ date: new Date(fd), performed: false, period: exercise.period });
      }))
      this.exercises[ex_index].assigned_dates = dates

    }
    else this.exercises.push(programExercise);

    this.exercises$.next([...this.exercises]);

  }
  deleteProgram() { }
  updateExercise(
    exercise_id: string,
    options: {
      sets?: number;
      inhale?: number;
      exhale?: number;
      repetitions?: number;
    }
  ) {
    const index = this.exercises.findIndex((e) => {
      return e.exercise_id == exercise_id;
    });
    if (index > -1) {
      const exercise = this.exercises[index];
      if (exercise.sets) exercise.sets = options?.sets;
      if (options.inhale) {
        exercise.actions[0].duration = options.inhale;
      }
      if (options.exhale) {
        exercise.actions[1].duration = options.exhale;
      }
      if (options.repetitions) {
        exercise.repetitions = options.repetitions;
      }
      this.exercises$.next([...this.exercises]);
    }
  }
  updateProgram() { }
  onExerciseDone() { }
  constructor({ program_id, active, deleted, exercises }: any) {
    this.program_id = program_id;
    this.deleted = deleted;
    this.active = active;

    this.exercises = (exercises as IProgramExercise[])
      ?.filter((e) =>   e.assigned_dates?.length > 0)
      ?.map((e) => new ProgramExercise(e));
    this.exercises$.next([...this.exercises]);
    this.events$ = new BehaviorSubject<CalendarEvent[]>([]);
    this._generateEvents();
  }

  toggleDateEvents(date: Date, exercisesList: any[]) {
    exercisesList.forEach(exercise => {

      const e = this.exercises.find(e => e.exercise_id == exercise.exercise_id)
      if (e) {
        const date_list = e.assigned_dates.filter(ad => ad.date.getTime() == date.getTime())
        if (date_list.length > 0) {
          e.assigned_dates = e.assigned_dates.filter(ad => ad.date.getTime() != date.getTime())
        } else {
          const orgninalExercise = exercisesList.find(x => x.exercise_id == exercise.exercise_id)
          const new_assigned_dates = orgninalExercise.assigned_dates.map(ad => new AssignedDate({ ...ad, date }))
          e.assigned_dates.push.apply(e.assigned_dates, new_assigned_dates)
        }
      }
    })


    this.exercises$.next([...this.exercises]);
    this._generateEvents()
    
  }
  addEventForDate(date: Date, exercisesList: any[]) {
    exercisesList.forEach(exercise => {
      const e = this.exercises.find(e => e.exercise_id == exercise.exercise_id)
      if (e) {

        const orgninalExercise = exercisesList.find(x => x.exercise_id == exercise.exercise_id)
        const new_assigned_dates = orgninalExercise.assigned_dates.map(ad => new AssignedDate({ ...ad, date }))

        for (const nd of new_assigned_dates) {
          if (!e.assigned_dates.find(d => d.date.getTime() == date.getTime() && d.period == nd.period)) {
            e.assigned_dates.push(nd)
          }
        }
        //e.assigned_dates.push.apply(e.assigned_dates, new_assigned_dates)


      }
    })


    this.exercises$.next([...this.exercises]);
    this._generateEvents()

  }
   
  deleteExercise(exercise_id: string, period: number) {
    const currentTime = new Date();
    currentTime.setHours(0, 0, 0, 0);
    const toBeModified = this.exercises.find((e) => e.exercise_id == exercise_id);
    if (toBeModified) {
      //keep the old assigned dates and modify the future dates
      toBeModified.assigned_dates = toBeModified.assigned_dates.filter(d => (d.date.getTime() < currentTime.getTime()) || (d.period != period || d.performed))
      if (toBeModified.assigned_dates.length == 0)
        toBeModified.deleted = true;
    }
    this.exercises$.next([...this.exercises]);
    this._events = this._events.filter((e) => {
      if (e.meta?.exercise_id == exercise_id && e.meta?.period == period) {
        if (e.start.getTime() < currentTime.getTime() || e.meta?.performed)
          return true;
        return false;
      }
      return true;
    });
    this.events$.next(this._events);
  }


  setLanguage(lang: string) {
    this.lang = lang;
    this._generateEvents();
  }
  getEvents(): CalendarEvent[] {
    return this._events;
  }
  getStartDate(): Date {
    const allDates = this.exercises.reduce((prev, curr, index) => {
      return [...prev, ...curr.assigned_dates];
    }, []) as any as IAssignedDate[];

    return new Date(Math.min(...allDates.map((ad) => ad.date.getTime())));
  }
  getEndDate(): Date {
    const allDates = this.exercises.reduce((prev, curr, index) => {
      return [...prev, ...curr.assigned_dates];
    }, []) as any as IAssignedDate[];

    return new Date(Math.max(...allDates.map((ad) => ad.date.getTime())));
  }
  getProgramId() {
    return this.program_id;
  }
  _generateEvents() {
    const currentTime = new Date();
    currentTime.setHours(0, 0, 0, 0);
    this._events = []
    this._events = this.exercises.reduce((prev, currentExercise, index) => {
      const eList = currentExercise?.assigned_dates?.map((ad) => {
        const evt = this._generateEvent({ ...currentExercise, period: ad.period }, ad.date, ad.performed);
        
        return evt;
      }) as CalendarEvent[];
      if (eList?.length > 0) return [...prev, ...eList];
      return prev;
    }, []) as any as CalendarEvent[];
    this.events$.next(this._events);
  }
  addEvent(exercise: IProgramExercise, date: Date) {
    const evt = this._generateEvent(
      {
        ...exercise,
        program_id: this.getProgramId(),
        exercise_id: exercise.exercise_id,
      } as IProgramExercise,
      date,
      false,
      colors.blue
    );
    const event_exists = this._checkIfEventAlreadyExists(evt)
    if (!event_exists) {

      this._events.push(evt);
      this.events$.next([...this._events, evt]);
    }
  }
  private _checkIfEventAlreadyExists(evt: CalendarEvent): boolean {
    const i = this._events.findIndex(
      (e) =>
        e.meta?.exercise_id == evt.meta?.exercise_id &&
        e.meta?.period == evt.meta?.period &&
        e.start.getTime() == evt.start.getTime()
    );
    return i > -1;
  }
  removeDayEvents(date: Date) {
    this._events = this._events.filter(
      (e) => e.start.getTime() != date.getTime()
    );
    this.events$.next(this._events);
  }
  _generateEvent(
    exercise: IProgramExercise,
    eventDate: Date,
    isPerformed: boolean = false,
    e_color?: EventColor
  ) {
    const currentTime = new Date();
    currentTime.setHours(0, 0, 0, 0);
    let color = e_color ?? colors.yellow;
    if (eventDate <= currentTime) {
      if (isPerformed) {
        //performed = true;
        color = colors.green;
      } else {
        //performed = false;
        if (eventDate < currentTime) {
          color = colors.red;
        }
      }
    }
    const eventId =
      exercise.exercise_id && exercise.program_id
        ? `${exercise.exercise_id}${exercise.program_id}`
        : uniqueId('evt-');
    const title = exercise?.display_name?.[this.lang] ?? exercise?.display_name?.['en'];
    const evt = {
      id: eventId,
      title,
      start: eventDate,
      allDay: true,
      color: color,
      meta: {
        performed: isPerformed,
        exercise_id: exercise.exercise_id,
        program_id: exercise.program_id,
        period: exercise.period
      },
    };
    return evt;
  }
}
export const getDateAsString = (date: Date) => {
  return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
};
export const getStringAsDate = (str: string) => {
  const [day, month, year] = str.split('.').map((p) => parseInt(p));
  return new Date(year, month - 1, day, 0, 0, 0, 0);
};
