import { IProgramExercise } from './IProgramExercise';
import { AssignedDate } from './AssignedDate';
import { IExerciseAction } from './IExerciseAction';

export class ProgramExercise implements IProgramExercise {
  _id: string;
  deleted?: boolean;
  period: number;
  program_id: string;
  exercise_id: string;
  assigned_dates: AssignedDate[];
  actions: IExerciseAction[];
  category: string;
  description: { en: string; de: string; tr?: string; it?: string };
  display_name: { en: string; de: string; tr?: string; it?: string };
  name: string;
  disabled?: boolean | undefined;
  initial_pose: { margin: string; pose: string; check?: boolean | undefined; };
  repetitions: number;
  set_break?: { active: boolean; duration: number; instruction: any; } |
    undefined;
  sets?: number | undefined;
  vertical?: boolean | undefined;

  constructor({
    _id, program_id, period, equipments, exercise_id, assigned_dates, actions, category, description, display_name, name, initial_pose, repetitions, set_break, sets, vertical
  }: any) {
    this._id = _id;
    this.equipments = equipments ?? {};
    this.program_id = program_id;
    this.exercise_id = exercise_id;
    this.period = period;
    this.actions = actions;
    this.category = category;
    this.description = description;
    this.display_name = display_name;
    this.name = name;
    this.initial_pose = initial_pose;
    this.repetitions = repetitions;
    this.set_break = set_break;
    this.sets = sets;
    this.vertical = vertical;
    this.assigned_dates = assigned_dates?.map(
      (assigned: { date: string; performed: boolean; period: number; }) => {
        return new AssignedDate({ ...assigned });
      }
    );
  }
  equipments: any;
}
