import {
  Component,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  NgZone,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PatientDetailsComponent } from '@components/patient-details/patient-details.component';
import BreathMentHelper, { getDateInString } from '@helpers/helper';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { SelectedExercise } from '@schemas/exercise.model';
import { SurveyQuestionBase } from '@schemas/survey/survey-question-base';
import { AuthService } from '@services/auth.service';
import { CustomDateFormatter } from '@services/calendar/custom-date-formatter.provider';

import { GeneralService } from '@services/general/general.service';
import { Exercise } from '@services/program-manager/Exercise';
import { StoreService } from '@services/store.service';
import { SurveyQuestionService } from '@services/survey/survey-question.service';
import { UserService } from '@services/user.service';
import { CalendarDateFormatter } from 'angular-calendar';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-patient',
  templateUrl: './patient.component.html',
  styleUrls: ['./patient.component.css'],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter,
    },
    SurveyQuestionService,
  ],
})
export class PatientComponent implements OnInit {
  @ViewChild('patientDetailsComponent', { static: false })
  patientDetailsCmp: PatientDetailsComponent;
  public locale: string;

  // public patient: Patient;
  public patient: any;

  public exercisesList: Array<Exercise> = [];
  public chosenExercise: Exercise;
  public exerciseDate: string;

  public dataLoaded = false;
  public exerciseMode = false;

  public exerciseDayIndex: number;

  // Survey
  public currentUser;

  public afterQuestions: SurveyQuestionBase<object>[] = [];
  public beforeQuestions: SurveyQuestionBase<object>[] = [];
  public selectedExercise: SelectedExercise;

  public remainingExercises: boolean;
  public userType: string = 'patient';
  public firstExercise = false;
  public emailSent: boolean = false;
  data: any;
  patientId: any;
  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private authService: AuthService,
    private modalService: NgbModal,
    private surveyService: SurveyQuestionService,
    private translate: TranslateService,
    private toastr: ToastrService,
    private generalService: GeneralService,
    private changeDetector: ChangeDetectorRef,
    private storeService: StoreService,
    private ngZone: NgZone
  ) {
    this.locale = this.translate.currentLang;
    this.translate.onLangChange.subscribe((value) => {
      this.translate.use(value.lang);
      this.locale = value.lang;
    });
    this.currentUser = authService.getAuthenticatedUser();

    this.patientId = this.currentUser.id;
    this.userType = this.currentUser.role;
    this.patient = this.authService.me;
    // this.translate.setDefaultLang(this.authService.me.locale);
    // this.translate.use(this.authService.me.locale);

    this.route.data.subscribe(({ data }) => {
      this.data = data;
      this.patient = data.userDetails;
      this.appendQuestions(data.beforeSurvey[`data`].questions, 'before');
      this.appendQuestions(data.afterSurvey[`data`].questions, 'after');
      this.ngZone.run(() => {
        this.dataLoaded = true;
      });
    });
  }

  ngOnInit(): void { }

  public getUser() {
    this.exercisesList = [];
    this.userService.getUserDetails(null).subscribe(
      (data) => {
        this.patient = data;
        this.translate.setDefaultLang(
          this.patient.personal_information.language
        );
        this.translate.use(this.patient.personal_information.language);
        localStorage.setItem(
          'language',
          this.patient.personal_information.language
        );
        this.dataLoaded = true;
        this.changeDetector.detectChanges();
      },
      (error) => {
        this.translate.get('toastr.userDataError').subscribe((res: string) => {
          this.toastr.error(res);
        });
        this.logout();
      }
    );
  }

  appendQuestions(questions, type) {
    questions.forEach((question, index) => {
      const q = structuredClone(question);
      q.key = index;
      q.value = '';
      if (type === 'after') {
        this.afterQuestions.push(q);
      }
      if (type === 'before') {
        this.beforeQuestions.push(q);
      }
    });
  }

  async prepareStartExerciseClicked(exercise, surveyModal) {
    this.chosenExercise = exercise.exercise;
    this.selectedExercise = exercise;

    // const requestDataBefore = {
    //   date: BreathMentHelper.currentDate(),
    //   patient_uid: this.patientId,
    //   survey_type: 'before',
    // };
    this.storeService.checkSurveyAvailability("before").subscribe(result=>{
      
      if(result==true){
        this.prepareSurvey(exercise, surveyModal);
      }else{
        this.startExercise('survey-completed', true);
      }
    })
    // this.surveyService
    //   .submittedSurveyChecker(requestDataBefore)
    //   .subscribe((response) => {
    //     if (response[`data`].length === 0) {
    //       this.prepareSurvey(exercise, surveyModal);
    //       return;
    //     }

    //     this.startExercise('survey-completed', true);
    //   });
  }

  prepareSurvey(exercise, surveyModal) {
    this.openModal(surveyModal);
  }

  startExercise($event: string, noSurvey: boolean = false) {
    if (!noSurvey) {
      this.closeSurveyModal();
    }

    this.exerciseDate = this.selectedExercise.date;
    this.exerciseMode = true;
    this.resetSelectedExercise();
  }

  startExerciseClicked(exercise) {

    this.chosenExercise = exercise.exercise;
    this.exerciseDate = exercise.date;
    this.firstExercise = exercise.first;
    this.exerciseMode = true;
  }

  endExercise($event: string, noSurvey: boolean = false) {
    if (!noSurvey) {
      this.closeSurveyModal();
    }
  }

  // openNextExercise() {
  //   this.patient.exercisePerformance[this.exerciseDayIndex].diaphragmBreathing.done = true;
  //   this.exerciseMode = false;
  //   setTimeout(() => {
  //     this.chosenExercise = this.exercisesList[1];
  //     this.assignExerciseParameters();
  //     this.exerciseMode = true;
  //   }, 10);
  // }

  closeExerciseMode() {
    this.dataLoaded = false;
    this.exerciseMode = false;
    this.getUser();
  }

  logout() {
    this.authService.logout();
  }

  openModal(content) {
    this.modalService.open(content, { size: 'lg' });
  }

  closeSurveyModal() {
    this.modalService.dismissAll();
  }

  resetSelectedExercise() {
    this.selectedExercise = { exercise: {}, date: '' };
  }

  exerciseClosed(event?, survey?) {
    this.exerciseMode = false;
    this.dataLoaded = false;
    const dateAsString = getDateInString(new Date());
    this.storeService
      .getPrograms(this.patientId, true)
      .subscribe(
        (response) => {

          this.data.allExercisePrograms = response;
          let isAllTodayExercisesDone = true;
          for (const program of response) {
            for (const exercise of program.exercises) {
              for (const assigned_date of exercise.assigned_dates) {
                if (
                  assigned_date.date == dateAsString &&
                  !assigned_date.performed
                ) {
                  isAllTodayExercisesDone = false;
                  break;
                }
              }
              if (!isAllTodayExercisesDone) break;
            }
            if (!isAllTodayExercisesDone) break;
          }
          this.storeService.checkSurveyAvailability("after").subscribe(result => {
            if (result === true) {//&& dateAsString == this.exerciseDate) {
              //show after survey
              this.openModal(survey);
            }
          })
        },
        (error) => { },
        () => {
          this.dataLoaded = true;
        }
      );

    // if (this.patientDetailsCmp?.dataLoaded) {
    //   this.remainingExercises = this.patientDetailsCmp.checkTodayExercises(true);

    //   if (this.remainingExercises) {
    //     const requestDataAfter = {
    //       date: BreathMentHelper.currentDate(),
    //       patient_uid: this.patientId,
    //       survey_type: 'after'
    //     };

    //     this.surveyService.submittedSurveyChecker(requestDataAfter).subscribe(response => {
    //       if (response[`data`].length === 0) {
    //         this.openModal(survey);
    //       }
    //     });
    //   }
    // } else {
    //   setTimeout(() => {
    //     this.exerciseClosed(event, survey);
    //   }, 1000);
    // }
  }

  resetPassword() {
    const requestData = {
      email: this.currentUser.personal_information.email,
    };
    this.generalService.sendPasswordResetEmail(requestData).subscribe(() => {
      this.emailSent = true;
      this.logout();
    });
  }

  useLanguage(language: string) {
    this.translate.setDefaultLang(language);
    this.translate.use(language);
    localStorage.setItem('language', language);
    this.dataLoaded = false;
    this.getUser();
  }

  todayRemainingExercises(event) {
    this.remainingExercises = event;
  }

  languageChanged(language: string): void {
    this.dataLoaded = false;
    this.locale = language;
    setTimeout(() => {
      this.dataLoaded = true;
    }, 100);
  }
}
