<div *ngIf="!dataLoaded" class="preloader vh-100 vw-100 d-flex justify-content-center align-items-center">
  <div class="spinner-border text-primary" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<app-navbar *ngIf="dataLoaded && !exerciseMode" (language)="languageChanged($event);"></app-navbar>
<div class="d-flex justify-content-end mr-5">
  <button type="button" class="btn btn-warning btn-circle btn-sm mr-4 " (click)="openModal(InformationModal);">
    <i class="fas fa-question" style="color: white"></i>
  </button>
</div>
<section *ngIf="dataLoaded && !exerciseMode" class="slice slice-lg pt-3">
  <div class="container">
    <app-patient-home [data]="data" [patient]="patient" [role]="userType" [physiotherapistExercises]="exercisesList"
      (goBackEvent)="prepareStartExerciseClicked($event, surveyBeforeExercise)"
      (remainingExercise)="todayRemainingExercises($event)" #patientDetailsComponent>
    </app-patient-home>
  </div>
</section>

<div *ngIf="exerciseMode">
  <app-breathing-exercise *ngIf="chosenExercise['category'] === 'breathing' "
    (closeTestViewEvent)="exerciseClosed($event, surveyAfterExercise)" [exercise]="chosenExercise"
    [exerciseDate]="exerciseDate" [selected_period]="chosenExercise?.period">
  </app-breathing-exercise>
  <app-breathing-time-exercise *ngIf="chosenExercise['category'] === 'breathing_time'"
    (closeTestViewEvent)="exerciseClosed($event, surveyAfterExercise)" [exercise]="chosenExercise"
    [exerciseDate]="exerciseDate" [selected_period]="chosenExercise?.period">
  </app-breathing-time-exercise>
  <app-relaxation-exercise *ngIf="chosenExercise['category'] === 'relaxation'"
    (closeTestViewEvent)="exerciseClosed($event, surveyAfterExercise)" [exercise]="chosenExercise"
    [exerciseDate]="exerciseDate" [selected_period]="chosenExercise?.period">
  </app-relaxation-exercise>
  <app-physical-exercise
    *ngIf="!['breathing', 'breathing_time', 'relaxation'].includes(chosenExercise['category'])"
    (closeTestViewEvent)="exerciseClosed($event, surveyAfterExercise)" [exercise]="chosenExercise"
    [exerciseDate]="exerciseDate" [selected_period]="chosenExercise?.period">
  </app-physical-exercise>

</div>

<!--Survey After Exercise-->
<ng-template #surveyAfterExercise let-modal>
  <div class="modal-body">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
    <div class="mb-4"></div>
    <app-dynamic-form [questions]="afterQuestions" [editMode]="false" (newItemEvent)="endExercise($event)"
      [type]="'after'">
    </app-dynamic-form>
  </div>
</ng-template>

<!--Survey modal-->
<ng-template #surveyBeforeExercise let-modal>
  <div class="modal-body">
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
    <div class="mb-4"></div>
    <app-dynamic-form [questions]="beforeQuestions" [editMode]="false" (newItemEvent)="startExercise($event)"
      [type]="'before'">
    </app-dynamic-form>
  </div>
</ng-template>

<!--Reset password modal-->

<ng-template #resetPasswordModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">{{ 'home.navigation.resetPassword' | translate }}</h5>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <div *ngIf="!emailSent">
      <p>{{'home.navigation.resetPasswordQuestion' | translate}}</p>
      <p>{{'home.navigation.resetPasswordText1' | translate}} <span class="font-italic text-primary">{{
          'generalTerms.yes' | translate }}</span>{{ 'home.navigation.resetPasswordText2' | translate }}</p>
    </div>
    <div *ngIf="emailSent">
      <p>{{ 'login.resetPasswordEmailSent' | translate }}</p>
    </div>

  </div>
  <div class="modal-footer">
    <button *ngIf="!emailSent" type="button" class="btn btn-sm btn-primary" (click)="resetPassword();">{{
      'generalTerms.yes' | translate }}</button>
    <button *ngIf="!emailSent" type="button" class="btn btn-sm btn-secondary"
      (click)="emailSent = false; modal.close();">{{ 'generalTerms.no' | translate }}</button>
    <button *ngIf="emailSent" type="button" class="btn btn-sm btn-secondary"
      (click)="emailSent = false; modal.close();">{{ 'generalTerms.close' | translate }}</button>
  </div>
</ng-template>
<ng-template #InformationModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title"><img class="mr-2" alt="Image placeholder"
        src="../../../assets/Logo/Breathment Logo_rev_white.jpg" style="height: 50px;" id="navbar-mobilr-logo"></h5>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">

    <h5>{{'tutorial.welcomeToBreathment' | translate}}</h5>
    <p>{{'tutorial.WelcomeToBreathmentPatient' | translate}} </p>
    <p>{{'tutorial.patientexp1' | translate}} </p>
    <p>{{'tutorial.patientexp2' | translate}} </p>
    <p>{{'tutorial.patientexp3' | translate}} </p>
    <p>{{'tutorial.patientexp4' | translate}} </p>

  </div>
  <div class="modal-footer">

    <button type="button" class="btn btn-sm btn-secondary" (click)="modal.close();">{{ 'generalTerms.close' | translate
      }}</button>
  </div>
</ng-template>